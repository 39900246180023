"use client";

import {
  createContext,
  FC,
  PropsWithChildren,
  useMemo,
} from "react";

import { CustomerAPI } from "@api-clients/customer";
import { DiscountsAPI } from "@api-clients/discounts";
import { FreshMealPlanAPI } from "apps/website/api";
import { useAuth } from "@auth/client-sdk-react";
import { CapiClient } from "@api-clients/capi";

import { PublicConfig } from "../config/public";
import { FormsActionsAPI } from "../api/FormsActions";
import { Statistics } from "../api/Statistics";
import { CheckoutAPI } from "../api/Checkout";
import { SurveyAnswers } from "../api/SurveyAnswers";

export interface ApiClientsContext {
  Checkout: CheckoutAPI;
  FreshMealPlan: FreshMealPlanAPI;
  Customer: CustomerAPI;
  FormActions: FormsActionsAPI;
  Capi: CapiClient;
  Statistics: Statistics;
  Discounts: DiscountsAPI;
  SurveyAnswers: SurveyAnswers;
  tokenStatus: {
    accessToken: string | undefined;
    loggedIn: boolean | undefined;
  };
}

export const apiContext = createContext<ApiClientsContext>(undefined as unknown as ApiClientsContext);

// Site-wide context for customer data (subscriptions, cats, etc.)
export const KatkinCustomerApiProvider: FC<PropsWithChildren> = ({ children }) => {

  const { loggedIn } = useAuth();
  const accessToken = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaXJzdE5hbWUiOiJKb2huIiwibGFzdE5hbWUiOiJEb2UiLCJzdWIiOiJhNWNkZTg3MS1kZGU5LTQxMzYtYWVlNC0wNjNlODVlN2YzMWIiLCJlbWFpbCI6ImpvaG5AYWRtaW4uY29tIiwiaWF0IjoxNzMzNzQ0NzkxLCJleHAiOjE3MzM3NDgzOTF9.D6UKEyECQfKU-5UDHNiga_H4xvlmZLZG1terGWIkXlaXqdsdTKys0ZSPZ3b5tlk8rO4VYxSSlDJTsrHS7ueC_QzIu0W_0bcqynijw2GbTLl2cMGEbnJgzvLrNw-IqD1GvdlQbtP3HfrQVOh4HpDOxU5t2wVc-mZvQnvRODwdfJc7GeJAOukqhHcINsLoML7N77oiEmfE4NVBsRo3haxXKHSraGpp840oNtNis2AcNDvnHsJv6mWQbov2IHQeyuFHPvXFjWHkaZyGTl5prDZv_kDQ4OK0e_LGpi6JFIn6B94dXamzN78xnTm_rGVdxbyV7Qg_s-ZtDnXHuhhNLkJLNx9yv5_OtDiT4XGvJP10j3H7eIV7M778mgsbYgbGYzkX5ndnAABA5pTc5w1x80gdxDdJ4LOVog0d3nLz9ZUm3UO3hJVoSG-egXXtN0PrxJpb7l2t7Nzrdgjmm5DkB5K506TCw41zVPUpih7H_pwybNEs_wXoE8rblhdVlV84uwO95S3xvcUJ-T1igE9xb1rhREwxtuycCh72DHxuYsvYbADlokpFuVdYo-o8H5oeJ1wjWdSNX3eyURw59PEq3hZWRKsPEde1C73mUq4CvkhsKqwnhuG_X--FWVQVrulZsd_GDjKu_byGniVSbaUnGLAsngALYCpgagDljkbX34yailo";
  const clients = useMemo(() => ({
    Checkout: new CheckoutAPI(PublicConfig.NEXT_PUBLIC_CHECKOUT_URL, accessToken),
    FreshMealPlan: new FreshMealPlanAPI(PublicConfig.NEXT_PUBLIC_OLD_API_BASE_URL, accessToken),
    FormActions: new FormsActionsAPI(PublicConfig.NEXT_PUBLIC_FORM_ACTIONS_URL, accessToken),
    Customer: new CustomerAPI(
      PublicConfig.NEXT_PUBLIC_OLD_API_BASE_URL,
      accessToken,
      { withCredentials: true },
    ),
    Discounts: new DiscountsAPI(
      PublicConfig.NEXT_PUBLIC_DISCOUNT_API_BASE_URL,
      accessToken,
    ),
    Capi: new CapiClient(
      PublicConfig.NEXT_PUBLIC_CAPI_BASE_URL,
      accessToken,
    ),
    Statistics: new Statistics(PublicConfig.NEXT_PUBLIC_STATISTICS_URL, accessToken),
    SurveyAnswers: new SurveyAnswers(PublicConfig.NEXT_PUBLIC_SURVEY_ANSWERS_BASE_URL, accessToken),
    tokenStatus: {
      accessToken,
      loggedIn,
    },
  }), [ loggedIn, accessToken ]);

  return (
    <apiContext.Provider value={clients}>
      { children }
    </apiContext.Provider>
  );
};
